<template>
    <div>
        <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
            <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
        <v-toolbar flat dense class="tc-title">
            <router-link to="student-experience" class="nav-arrow-left"><v-icon
                    class="left-arrow">mdi-arrow-left-bold</v-icon></router-link>
            <v-toolbar-title>Offer Letter</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-btn color="primary" dark class="logi mx-2" fab small
                @click="dialog_add = true"><v-icon>mdi-plus</v-icon></v-btn>
            <router-link to="student-publication" class="nav-arrow-right"><v-icon
                    class="right-arrow">mdi-forward</v-icon></router-link>
        </v-toolbar>
        <!--<template>
            <v-toolbar flat dense color="white">
                
                 <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details>
                    </v-text-field>
                    <v-spacer></v-spacer>
                    <span style="width: 20px"></span>
                <v-spacer></v-spacer>
                <span style="width: 20px;"></span>
                <template>
                    <v-btn color="primary" dark class="mb-2" @click="dialog_add=true"><v-icon>mdi-plus</v-icon> Extra Curricular</v-btn>
                </template>
            </v-toolbar>
        </template>
        <template>
            <v-data-table :headers="headers" :items="learner_Extra_Curricular_list" class="elevation-1" :search="search">
                <template v-slot:item.docUrl="{ item }">
                    <span v-if="item.docUrl=='NA'">
                   {{ item.docUrl }}
                    </span>
                    <span v-else>
                       <a v-bind:href="item.docUrl" target="blank" class="alink"><v-icon color="success">mdi-cloud-download</v-icon></a>
                    </span>
                </template>
                
                <template v-slot:item.edit="{ item }">
                    <v-icon color="indigo" @click="editItem(item)">mdi-pencil-circle</v-icon>
                </template>
                <template v-slot:item.delete="{ item }">
                   <v-icon color="red" @click="del(item)">mdi-delete-circle</v-icon>
                </template>
            </v-data-table>
        </template>-->

        <v-row>
            <v-col cols="12" sm='4' v-for="(item, index) in learnerOfferLetter" :key="index">
                <v-card style=" margin: 8px; height:100%">
                    <v-card-title class="c-title">
                        {{ item.company.name }}

                    </v-card-title>
                    <v-card-text>

                        <v-row style="margin: 0px !important; padding: 0px !important">
                            <v-col cols="6" sm="4" style="margin: 0px !important; padding: 0px !important">
                                <span class="lbl">Filename</span>
                            </v-col>

                            <v-col cols="6" md="6" style="margin: 0px !important; padding: 0px !important">
                                : <a v-bind:href="item.offerletter_file" target="blank" class="alink">{{
                                    item.offerletter_filename
                                }}</a>

                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-icon color="indigo" @click="editItem(item)">mdi-pencil-circle</v-icon>
                        <v-spacer></v-spacer>
                        <v-icon color="red" @click="del(item)">mdi-delete-circle</v-icon>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>

        <template>
            <v-row justify="center">
                <v-dialog v-model="dialog_add" persistent max-width="550px">
                    <v-card>
                        <v-card-title class="card-title">
                            Add Offer Letter
                        </v-card-title>
                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col cols="12" sm="12">
                                        <label>Company<span class="required">*</span> :</label>
                                        <v-autocomplete v-model="savedata.company" :items="company_list" item-text="name"
                                            item-value="id" :rules="[v => !!v || 'required']"></v-autocomplete>
                                    </v-col>

                                    <v-col cols="12" sm="12">
                                        <label>Offer Letter<span class="required">*</span> :</label>
                                        <v-file-input type="file" placeholder="Select Offer Letter" prepend-icon="mdi-file"
                                            ref="file" show-size v-model="savedata.file"
                                            :error-messages="error.savedata.file ? 'Please Select Offer Letter' : ''"
                                            height="50px" clearable></v-file-input>
                                        <font style="color: red; font-size:11px; ">&emsp;&emsp;File Size Should be less than
                                            250 kb,JPEG,
                                            PNG & PDF </font>
                                    </v-col>
                                    <!-- <v-col cols="12" sm="12">
                                        <v-checkbox label="Is Default?" v-model="savedata.isdefault"></v-checkbox>
                                    </v-col> -->
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <!-- <v-btn color="blue darken-1" text @click="dialog_add= false">Close</v-btn>
                            <v-btn color="blue darken-1" text @click="save()">Save</v-btn> -->
                            <v-btn color="primary darken-1" outlined text @click="dialog_add = false">Cancel</v-btn>
                            <v-btn color="primary darken-1" dark @click="save()">Save</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>
        </template>
        <template>
            <v-row justify="center">
                <v-dialog v-model="dialog_edit" persistent max-width="550px">
                    <v-card>
                        <v-card-title class="card-title">
                            Edit Offer Letter
                        </v-card-title>
                        <v-card-text>
                            <v-container>
                                <v-row>

                                    <!-- <v-col cols="12" sm="12">
                                        <label>Company<span class="required">*</span> :</label>
                                        <v-select :items="company_list" v-model="editedItem.company" item-text="name" item-value="id"  :rules="[v => !!v || 'required']"></v-select>
                                    </v-col> -->
                                    <v-col cols="12" sm="12">
                                        <label>Offer Letter :</label>
                                        <v-file-input type="file" placeholder="Select Offer Letter" prepend-icon="mdi-file"
                                            required="true" clearable v-model="editedItem.file"></v-file-input>
                                        <span v-if="addedfile != ''">
                                            FileName : {{ addedfile }}
                                        </span>
                                        <br>
                                        <font style="color: red; font-size:11px; ">&emsp;&emsp;File Size Should be less than
                                            250 kb,JPEG,
                                            PNG & PDF </font>




                                    </v-col>

                                    <!-- <v-col cols="12" sm="12">
                                        <v-checkbox label="Is Default?" v-model="editedItem.isdefault"></v-checkbox>
                                    </v-col> -->
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>

                            <v-btn color="primary darken-1" outlined text @click="dialog_edit = false">Cancel</v-btn>
                            <v-btn color="primary darken-1" dark @click="edit()">Save</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>
        </template>
        <template>
            <div class="text-center">
                <v-dialog v-model="dialog_upload" hide-overlay persistent width="300">
                    <v-card color="primary" dark>
                        <v-card-text>
                            Please wait File uploding...
                            <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </div>
        </template>
        <template>
            <v-row justify="center">
                <v-dialog v-model="dialog_delete" persistent max-width="290">
                    <v-card>
                        <v-card-title class="del-card-title">Offer Letter Delete</v-card-title>
                        <input type="hidden" v-model="deleteItem.id" />
                        <v-card-text>
                            Are you sure you want Delete this Offer Letter?
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="green darken-1" text @click="dialog_delete = false">No</v-btn>
                            <v-btn color="red darken-1" text @click="deletedata()">Yes</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>
        </template>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
import axios from "axios";
import StudentProfile from "./StudentProfile";
export default {
    components: {
        StudentProfile,
    },
    data: () => ({
        dialog_upload: false,
        learnerOfferLetter: "",
        savedata: {
            file: null,
            company: null,
            id: "",

            // isdefault:false,
        },
        error: {
            savedata: {
                file: false,

            },
        },
        company: "",
        company_id: "",
        company_list: "",
        overlay: false,
        learnerOfferLetter: "",
        dialog_add: false,
        dialog_edit: false,
        snackbar_msg: "",
        color: "",
        snackbar: false,
        search: "",
        editFile: '',
        addedfile: '',

        editedItem: {
            file: null,
            company: null,
            //    isdefault:false,
            id: "",
        },
        deleteItem: {
            id: "",
        },
        avtivityType: {
            id: "",
            activitytype: "",
        },
        dialog_delete: false,

    }),

    watch: {
        dialog(val) {
            val || this.close();
        },
        overlay(val) {
            val = () => {
                this.overlay = false;
            };
        },
    },
    mounted() {
        this.onLoad();
    },
    methods: {
        onLoad() {
            axios
                .post("/LearnerData/learnerOfferLetter")
                .then((res) => {
                    if (res.data.msg == "200") {
                        this.company_list = res.data.company_list;
                        this.learnerOfferLetter = res.data.learnerOfferLetter
                        // window.alert(JSON.stringify(this.learnerCV))
                        //console.log("in onload");
                        //console.log( this.learnerOfferLetter);

                    } else {
                        this.showSnackbar("#b71c1c", "No Data");
                        //window.console.log(res.data.msg);
                    }
                })
                .catch((error) => {
                    this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
                    //window.console.log(error);
                })
                .finally(() => {
                    // 'use strict';
                });
        },
        showSnackbar(clr, msg) {
            this.snackbar = true;
            this.color = clr;
            this.snackbar_msg = msg;
        },
        editItem(item) {

            this.dialog_edit = true;
            this.editedItem.id = item.id;
            this.addedfile = item.offerletter_filename;
            // this.editedItem.isdefault = item.isdefault;
            //this.editedItem.file=item.filename;

        },
        del(item) {
            this.dialog_delete = true;
            this.deleteItem.id = item.id;
        },
        close() {
            this.dialog = false;
            setTimeout(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            }, 300);
        },
        save() {
            this.overlay = true;
            if (this.savedata.company == null) {
                this.showSnackbar("#b71c1c", "Select company ...");
                return;
            }
            else if (this.savedata.file == null) {

                this.showSnackbar("#b71c1c", "Please Select A File...");
                this.error.savedata.file = true;
                return;
            }

            else if (this.savedata.file) {
                //for only allow extensions while upload (e.g. .pdf  .jpeg  .png)
                console.log(this.savedata.file.name);
                var file = this.savedata.file.name;
                console.log(file);
                var ext = file.split(".");
                ext = ext[ext.length - 1].toLowerCase();
                var arrayExtensions = ["pdf", "jpeg", "png"];

                if (arrayExtensions.lastIndexOf(ext) == -1) {
                    // alert("Invalid file.. Only pdf can be uploaded");
                    this.showSnackbar("#b71c1c", "Please Select A only PDF, JPEG & PNG File...");
                    return
                }

                //for only allow upload cv when size is 250kb
                const upload = this.savedata.file.size;
                const maxSize = 250 * 1024;
                if (upload > maxSize) {
                    this.showSnackbar("#b71c1c", "File size exceeds the limit (250kb)");
                    return
                }
            }

            let formData = new FormData();
            formData.append("company", this.savedata.company);
            formData.append("file", this.savedata.file);


            this.dialog_add = false;
            //this.dialog_upload = true;
            axios
                .post("/LearnerData/saveOfferLetterinlearner", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((res) => {
                    if (res.data.msg == "200") {
                        if ((this.load = true)) {
                            this.overlay = false;
                            this.onLoad();
                            this.dialog_upload = false;
                            this.showSnackbar("#4caf50", "offer letter Addded Successfully..."); // show snackbar on success
                        }

                    } else {
                        this.dialog_upload = false;
                        this.showSnackbar("#b71c1c", "offer letter Already Present!!!");
                        this.overlay = false;
                    }
                })
                .catch((error) => {
                    //window.console.log(error);
                    this.overlay = false;
                });

            this.close();

        }, // end of Save()
        edit() {
            if (this.editedItem.file) {

                var file = this.editedItem.file.name;

                var ext = file.split(".");
                ext = ext[ext.length - 1].toLowerCase();
                var arrayExtensions = ["pdf", "jpeg", "png"];

                if (arrayExtensions.lastIndexOf(ext) == -1) {
                    // alert("Invalid file.. Only pdf can be uploaded");
                    this.showSnackbar("#b71c1c", "Please Select A only PDF, JPEG & PNG File...");
                    return
                }

                //for only allow upload cv when size is 250kb
                const upload = this.editedItem.file.size;
                const maxSize = 250 * 1024;
                if (upload > maxSize) {
                    this.showSnackbar("#b71c1c", "File size exceeds the limit (250kb)");
                    return
                }
            }
            let formData = new FormData();
            formData.append("id", this.editedItem.id);
            formData.append("file", this.editedItem.file);
            // formData.append("company", this.savedata.company);
            this.dialog_edit = false;
            this.dialog_upload = true;
            axios
                .post("/LearnerData/editOfferLetter", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((res) => {
                    if (res.data.msg == "200") {
                        this.dialog_edit = false;
                        this.dialog_upload = false;
                        this.showSnackbar("#4caf50", "offer letter Updated Successfully..."); // show snackbar on success
                        this.onLoad();
                    } else {
                        this.dialog_upload = false;
                        this.showSnackbar("#b71c1c", "offer letter Already Present!!!");
                    }
                })
                .catch((error) => {
                    //window.console.log(error);
                });
            this.close();


        }, // end of edit()
        deletedata() {
            //alert("Are you sure Delete....")
            //this.deleteItem.lactivity_id=item.id
            this.dialog_delete = false;
            axios.post("/LearnerData/deleteOfferLetter", this.deleteItem).then((res) => {
                if (res.data.msg == "200") {
                    if ((this.load = true)) {
                        this.onLoad();
                        this.showSnackbar("#4caf50", " offer letter File Deleted Successfully...");
                        th;
                    }
                } else {
                    this.showSnackbar("#b71c1c", "offer letter File Not Deleted...");
                }
            });
        },
    },
};
</script>
<style scoped>
.nav-arrow-left {
    margin-right: 5px;
    text-decoration: none;
    color: #FFF;
}

.nav-arrow-right {
    margin: 5px;
    text-decoration: none;
    color: rgb(3, 55, 167);
}

.left-arrow {
    color: #FFF;
}

.right-arrow {
    color: rgb(3, 55, 167);
}

.ec-title {
    background-image: linear-gradient(-100deg, #f4faba, #d64907);
    color: #FFF;
}

.c-title {
    background-image: linear-gradient(-90deg, #befcf7, #03948d);
    color: #FFF;
}

.tc-title {
    background-image: linear-gradient(-90deg, #FFF, #057996);
    color: #FFF;
    border-radius: 3px;
}

.logi {
    background-image: linear-gradient(-100deg, #8aa5f7, #063bdb);
    color: #FFF;
}

.card-title {
    background-image: linear-gradient(-90deg, #8edffd, #034c70);
    color: #FFF;
}

.del-card-title {
    background-image: linear-gradient(-90deg, #fde7e7, #ac0404);
    color: #FFF;
}

.lbl {
    color: #1705e6;
}

.v-text-field {
    padding-top: 0px;
    margin-top: 0px;
}

.alink {
    text-decoration: none;
}

.v-icon-size {
    font-size: 20px;
}

.edit-avatar {
    transition: 0.3s;
    border: 1px solid #b0bec5;
}

.edit-avatar:hover {
    background-color: #b0bec5;
}

.edit-v-icon {
    padding-left: 6px;
    font-size: 15px;
}

.edit-v-icon:hover {
    color: white;
}

.required {
    color: red;
    font-size: 14px;
    font-style: bold;
}
</style>
